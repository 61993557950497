<template>
  <div>
    <!-- <v-tooltip>
      <v-tooltip-title> Cambio de estado </v-tooltip-title>
    </v-tooltip> -->
    <v-card class="pa-4">
      <v-list dense>
        <v-list-item-group v-model="nuevoFeedback">
          <v-list-item
            v-for="feedback in feedbacks"
            :value="feedback"
            :key="feedback.val"
          >
            <v-chip class="v-chip--active" outlined label :color="feedback.color">
              <v-icon left>{{ feedback.icon }}</v-icon>
              {{ feedback.title }}
            </v-chip>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <div class="d-flex ma-4" v-if="nuevoFeedback">
        <v-chip
          class="v-chip--active"
          outlined
          label
          :color="nuevoFeedback.color"
        >
          <v-icon left>{{ nuevoFeedback.icon }}</v-icon>
          {{ nuevoFeedback.title }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click.stop="cambiar">Confirmar</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    idEvento: Number,
  },
  data() {
    return {
      feedbacks: [
        {title:"NS / NC", val : 1, icon: 'mdi-help', color: 'neutral'}, 
        {title:"Valoración Negativa", val : 2, icon: 'mdi-emoticon-sad-outline', color: 'error'},
        {title:"Valoración Neutra", val : 3, icon: 'mdi-emoticon-neutral-outline',color: 'warning'},
        {title:"Valoración Positiva", val : 4, icon: 'mdi-emoticon-happy-outline',color: 'success'},
      ],
      nuevoFeedback: null,
    };
  },
  methods: {
    getEstados() {
      return new Promise(async (resolve) => {
        resolve();
      });
    },
    cambiar() {
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/eventos/cambioFeedback`,
        data: {
          idEvento: this.idEvento,
          nuevoFeedback: this.nuevoFeedback.val,
        },
      }).then((res) => {
        this.$emit("reload");
        this.$emit("close");
        this.$root.$emit("snack", 'Se ha cambiado el feedback con éxito');
      }).catch(err => {
				console.error(err)
        this.$root.$emit("snack", 'No se ha podido cambiar el feedback');
        this.$emit("close");
			})
    },
  },
  mounted() {
    this.getEstados();
  },
};
</script>

<style>
</style>